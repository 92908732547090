import React from 'react';
import { SubmissionResponse } from '@wix/forms-ui/types';
import { FormControllerActions } from '../Actions/actions';

export type FormComponentContextType = {
  isBookingsOnEcom: boolean;
};

export const FormComponentContext = React.createContext<FormComponentContextType>(
  {} as FormComponentContextType,
);
export const FormComponentContextProvider = FormComponentContext.Provider;

export const useFormComponentContext = () =>
  React.useContext(FormComponentContext);
